//*******************************************************************************************************************
//File Name          :   profile.js
//Function           :   Profile form react component for editing user information.
//Created By         :   
//Created on         :   
 
//Revision History   :
//Date           Developer   Ticket#         Modification Description
//--------------------------------------------------------------------------------------------------
//20-Feb-2025    CV          (HDT#:777867)   Modified get domain default API call to include category
//*******************************************************************************************************************
import React, {useEffect, useRef, useState} from "react";
import "./profile.scss";
import Form, {SimpleItem, GroupItem} from "devextreme-react/form";
import {confirm, alert} from "devextreme/ui/dialog";
import {Button} from "devextreme-react/button";
import List from "devextreme-react/list";
import TabPanel, {Item} from "devextreme-react/tab-panel";
import appInfo from "../../app-info";
import {useAuth} from "../../contexts/auth";
import DataSource from "devextreme/data/data_source";
import {LoadPanel} from "devextreme-react/load-panel";

export default () => {
	const {user} = useAuth();
	let currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
	let userData = user ? user.data.userData : '';
	const defaultValue = user ? userData.language: '';

	const [serverList, setServerList] = useState([]);
	const [serverFavorites, setServerFavorites] = useState([]);
	const [domainList, setDomainList]= useState();
	const [initialKeys, setInitialKeys] = useState([]);
	const [showLoadPanel, setShowLoadPanel] = useState(false);
	const [domainSelected, setDomainSelected]= useState();
	//const [defaultLang,setDefaultLang]= useState(defaultValue);
	let tempGlobalArrayFavorites = [];
	let favoriteKeys = [];
	const timezoneList = ["EN", "ES"];

	const buildServerList = (serverList) => {
		serverList.map((item) => {
			if (item.isFavorite === true) {
				favoriteKeys.push(item.id);
			}
		});

		setServerList(serverList);
		setServerFavorites(favoriteKeys);
		setInitialKeys(favoriteKeys);
	};
	useEffect(() => {
		(async () => {
			let url = `${appInfo.authURL}authentication/defaultserver?username=${userData.userName}`;
			let getDefaultDomain =  `${appInfo.authURL}authentication/domainname?username=${userData.userName}&servername=${userData.serverName}&category=${userData.category}`;
			let getDomainList = `${appInfo.authURL}authentication/domains`;
			await fetch(url, {
				method: "get",
				headers: {authorization: `Bearer ${user.data.token}`}
			})
				.then((response) => response.json())
				.then((response) => {
					buildServerList(response);
				})
				.catch((err)=> console.warn(err));
			await fetch(getDomainList,
				{
					method:'get',
					headers: {authorization : `Bearer ${user.data.token}`}
				})
				.then((response)=> response.json())
				.then((response) => {
					let customList = response.map((domain) => domain.name);
					setDomainList(customList);
				})
				.catch((err)=> console.warn(err));
			await fetch(getDefaultDomain,
				{
					method:'get',
					headers: {authorization : `Bearer ${user.data.token}`}
				})
				.then((response)=> response.json())
				.then((response) => {
					if(response.name){
						setDomainSelected(response.name);
					}else{
						setDomainSelected("NODOMAIN");
					}
				
				})
				.catch((err)=> console.warn(err));
		})();
	}, []);

	const timezoneOptions = {
		items: timezoneList,
		value: defaultValue,
		searchEnabled:true
	};
	const domainOptions = {
		searchEnabled:'true',
		items: domainList,
		value: domainSelected,
	}
	const readOnlyItems = {
		readOnly: true
	};

	const dataSourceList = new DataSource({
		store: serverList,
		group: "zone"
	});
	const employee = {
		ID: userData.userID,
		Name: userData.employeeName,
		Email: userData.email,
		GroupCode: userData.groupCode,
		Manager: userData.manager,
		UserName: userData.userName,
		EmployeeCode: userData.employeeCode,
		Language: userData.language,
		Server: userData.serverName,
		Domain: domainSelected,
		Category: userData.category,
	};
	const sumbitAction = async () => {
		const dialogResult = await confirm("Are you sure you want to save?");
		if (dialogResult) {
			let languageUpd = employee.Language;
			let formatDate;
			//We need to move the userData from sessionStorage to user Context hook
			currentUser.data.userData.language = languageUpd;
			currentUser.data.userData.language === "ES"
				? (formatDate = "dd/MM/yyyy")
				: (formatDate = "MM/dd/yyyy");
			currentUser.data.userData.formatDate = formatDate;
			//Update sessionStorage Domain
			currentUser.data.userData.domain = employee.Domain;
			sessionStorage.setItem("currentUser", JSON.stringify(currentUser));

			//let's update both ways until we update all files, so nothing get break
			user.data.userData.language = languageUpd;
			user.data.userData.language === "ES"
				? (formatDate = "dd/MM/yyyy")
				: (formatDate = "MM/dd/yyyy");
			user.data.userData.formatDate = formatDate;
			user.data.userData.domain =  employee.Domain;
			
			//Now update the default Domain in DB
			await fetch(`${appInfo.authURL}authentication/domainname?username=${employee.UserName}&domain=${employee.Domain}&category=${employee.Category}&server=${employee.Server}`,
				{
					method: 'post',
					headers: {authorization : `Bearer ${user.data.token}`}
				})
				.then(response => response.json())
				.then((response) => {
					if(response.message)
					alert(response.message);
				})
		}
	};
	const btnEditorOptions = {
		type: "normal",
		icon: "save",
		text:' Save Changes',
		onClick: sumbitAction
	};
	const onSelectedItemKeysChange = ({name, value}) => {
		if (name === "selectedItemKeys") {
			tempGlobalArrayFavorites = value;
		}
	};
	const saveFavoritesAction = async (e) => {
		let objServers = [];
		let zones = [];
		let buildZoneArray = [];
		let favoritesObjServers;
		let url = `${appInfo.authURL}authentication/defaultserver`;
		let anyDifferentKeys = (e.promise = equalSelected());
		
		if (!anyDifferentKeys) {
			alert("No changes to save");
			return;
		}
		setShowLoadPanel(true);
		tempGlobalArrayFavorites.map((key) => {
			serverList.find((server) => {
				if (server.id === key) {
					objServers.push(server);
					zones.push(server.zone);
				}
			});
		});

		//1. remove duplicates zones
		let filteredZones = zones.reduce(
			(value, index) => (value.includes(index) ? value : [...value, index]),
			[]
		);

		//2. filter servers by zone
		let currentServer = [];
		filteredZones.map((zone) => {
			objServers.find((server) => {
				if (server.zone === zone) {
					currentServer.push({Name: server.name});
				}
			});
			//build object array
			buildZoneArray.push({Category: zone, Servers: currentServer});
			currentServer = [];
		});
		favoritesObjServers = {
			userName: userData.userName,
			Zones: buildZoneArray
		};
		await fetch(url, {
			method: "post",
			headers: {
				authorization: `Bearer ${user.data.token}`,
				"Content-Type": "application/json"
			},
			body: JSON.stringify(favoritesObjServers)
		})
			.then((response) => response.json())
			.then((response) => {
				alert(response.message);
				setInitialKeys(tempGlobalArrayFavorites);
				setShowLoadPanel(false);
			})
			.catch((err) => {
				console.warn(err);
				setShowLoadPanel(false);
			});
	};
	const itemRender = (data) => {
		return <div> {data.name}</div>;
	};
	function equalSelected() {
		//first check that length is equal

		//if the temporary array is empty then there's no change to make

		if (initialKeys.length != tempGlobalArrayFavorites.length) {
			return true;
		}
		if (!tempGlobalArrayFavorites.length) {
			return false;
		}

		let initialKeyArray = initialKeys.sort();
		let latestKeyArray = tempGlobalArrayFavorites.sort();

		let arraySize = latestKeyArray.length;

		for (let i = 0; i < arraySize; i++) {
			if (initialKeyArray[i] != latestKeyArray[i]) {
				return true;
			}
		}

		return false;
	}

	return (
		<React.Fragment>
			<h2 className={"content-block"}>Profile</h2>
			<div className={"content-block dx-card responsive-paddings"}>
				<TabPanel>
					<Item title='User Profile' icon='user'>
						<Form id={"form"} className='profile-form' labelLocation={"top"} formData={employee}>
							<GroupItem colCount={3}>
								<GroupItem>
									<SimpleItem dataField='ID' editorOptions={readOnlyItems} />
									<SimpleItem dataField='Name' editorOptions={readOnlyItems} />
									<SimpleItem dataField='Email' editorOptions={readOnlyItems} />
								</GroupItem>
								<GroupItem>
									<SimpleItem dataField='GroupCode' editorOptions={readOnlyItems} />
									<SimpleItem dataField='Manager' editorOptions={readOnlyItems} />
									<SimpleItem dataField='EmployeeCode' editorOptions={readOnlyItems} />
								</GroupItem>
								<GroupItem>
									<SimpleItem dataField='UserName' editorOptions={readOnlyItems} />
									<SimpleItem
										dataField='Language'
										editorType='dxSelectBox'
										editorOptions={timezoneOptions}
									/>
									<SimpleItem
										dataField='Domain'
										editorType='dxSelectBox'
										editorOptions={domainOptions}
									/>
								</GroupItem>
								<GroupItem>
									<SimpleItem
											editorType='dxButton'
											editorOptions={btnEditorOptions}
											onClick={sumbitAction}
										/>
								</GroupItem>
							</GroupItem>
						</Form>
					</Item>
					<Item title='Manage favorites' icon='favorites'>
						<div className='widget-container'>
							<List
								dataSource={dataSourceList}
								height={400}
								keyExpr='id'
								displayExpr='name'
								grouped={true}
								showSelectionControls={true}
								selectionMode={"multiple"}
								collapsibleGroups={true}
								itemRender={itemRender}
								defaultSelectedItemKeys={serverFavorites}
								onOptionChanged={onSelectedItemKeysChange}
							/>
							<LoadPanel
								visible={showLoadPanel}
								showIndicator={true}
								shading={true}
								showPane={true}
							/>
							<div className='selected-data'>
								<span>
									<Button
										icon='save'
										hint='Save Changes'
										text=' Save Favorites'
										onClick={saveFavoritesAction}></Button>
								</span>
							</div>
						</div>
					</Item>
				</TabPanel>
			</div>
		</React.Fragment>
	);
};
