//*******************************************************************************************************************
//File Name          :   ComponentCodeMasterGrid.js
//Function           :   Component Code Master data grid and CRUD functions
//Created By         :   
//Created on         :   
 
//Revision History   :
//Date           Developer   Ticket#         Modification Description
//--------------------------------------------------------------------------------------------------
//03-Mar-2025    CV          (HDT#:784367)   Add select file button styles to show the fulll button label.
//05-Mar-2025    CV          (HDT#:784207)   Removed character limit in search part textbox 
//*******************************************************************************************************************
import React, {useState, useRef, useEffect, useCallback} from "react";
import DataGrid, {
	Column,
	Editing,
	Paging,
	FilterRow,
	SearchPanel,
	Toolbar,
	Item,
	Pager,
	ColumnFixing,
	Export,
	Scrolling,
	Sorting,
	RequiredRule,
	LoadPanel,
} from "devextreme-react/data-grid";
import {CheckBox} from "devextreme-react/check-box";
import FileUploader from "devextreme-react/file-uploader";
import Button from "devextreme-react/button";
import {getPermission, setMode, getMode} from "./helpers/globalFunctions";
import {confirm, alert} from "devextreme/ui/dialog";
import {Workbook} from "exceljs";
import {exportDataGrid} from "devextreme/excel_exporter";
import saveAs from "file-saver";
import appInfo from "../../app-info";
import axios from "axios";
import {Redirect} from "react-router-dom";
import SEWSDropDownBoxComponent from "./helpers/ComponentCodeDropDownBox";
import IconFilter from "../../components/filter.svg";

export default ({
	customDataSource,
	plantId,
	deApproveGroup,
	qaApproveGroup,
}) => {
	const allowedPageSizes = [10, 30, 50, "all"];
	let currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
	let token = currentUser ? currentUser.data.token : '';
	let userGroupCode = currentUser ? currentUser.data.userData.groupCode :'';
	let language = currentUser ? currentUser.data.userData.language : '';
	const permission = getPermission(
		currentUser,
		"ConfigurationMasters",
		"ComponentCodeMaster"
	);
	let finalMsg = "";
	let canceled = false;
	const dataGrid = useRef(null);
	const [allowEditDEApproval, setAllowEditDEApproval] = useState(false);
	const [allowEditQaApproval, setallowEditQaApproval] = useState(false);
	const [totalCount, setTotalCount] = useState(0);
	const [dataGridSettings, setdataGridSettings] = useState({
		selectTextOnEditStart: true,
		startEditAction: "click",
		showHeaderFilter: true,
		showPageSizeSelector: true,
		showFilterRow: true,
		showInfo: true,
		showNavButtons: true,
		viewmode: true,
		infiniteScroll: "standard",
	});

	const groupCodeCheck = () => {
		/* check if user belong to QA Approve Group */
		if (qaApproveGroup !== null) {
			qaApproveGroup = JSON.stringify(qaApproveGroup).replaceAll('"', "");
			let DEGroupSearch = qaApproveGroup
				.split(",")
				.filter((group) => group === userGroupCode);
			if (DEGroupSearch.length > 0) {
				setallowEditQaApproval(true);
			}
		}
		/* check if user belong to DE Approve Group */
		if (deApproveGroup !== null) {
			deApproveGroup = JSON.stringify(deApproveGroup).replaceAll('"', "");
			let DEGroupSearch = deApproveGroup
				.split(",")
				.filter((group) => group === userGroupCode);
			if (DEGroupSearch.length > 0) {
				setAllowEditDEApproval(true);
			}
		}
	};

	/* ----- Initial Setup ----- */
	const clearFilters = () => {
		dataGrid.current.instance.clearFilter();
	};
	const onExporting = (e) => {
		/* Get today Date First */
		let today = new Date();
		let month = today.getMonth() + 1; //months from 1-12
		let day = today.getDate();
		let year = today.getUTCFullYear();

		let hours = today.getHours();
		let minutes = today.getMinutes();
		let ampm = hours >= 12 ? "pm" : "am";
		hours = hours % 12;
		hours = hours ? hours : 12; // the hour '0' should be '12'
		minutes = minutes < 10 ? "0" + minutes : minutes;
		let fullTime = hours + ":" + minutes + " " + ampm;
		let dateFormatted;
		language === "ES"
			? (dateFormatted = `${day}-${month}-${year}`)
			: (dateFormatted = `${month}-${day}-${year}`);
		const buildTitle = `Component Code Master ${dateFormatted}.xlsx`;
		const workbook = new Workbook();
		const worksheet = workbook.addWorksheet(buildTitle);

		exportDataGrid({
			component: e.component,
			worksheet: worksheet,
			topLeftCell: {row: 6, column: 1},
			customizeCell: ({gridCell, excelCell}) => {
				if (gridCell.rowType === "header") {
					excelCell.fill = {
						type: "pattern",
						pattern: "solid",
						fgColor: {argb: "e8e9eb"},
					};
				}
				if (gridCell.rowType === "data" && gridCell.data.isModified === true) {
					excelCell.fill = {
						type: "pattern",
						pattern: "solid",
						fgColor: {argb: "F8F400"},
					};
				}
			},
		}).then((cellRange) => {
			/* Header */
			const headerRow = worksheet.getRow(2);
			headerRow.height = 30;
			worksheet.mergeCells(2, 1, 2, 8);

			headerRow.getCell(1).value = "Component Code Report";
			headerRow.getCell(1).font = {name: "Segoe UI Light", size: 20};
			headerRow.getCell(1).alignment = {horizontal: "center"};

			const detailsRow = worksheet.getRow(4);
			detailsRow.getCell(2).value = "Plant ID: " + plantId;
			detailsRow.getCell(3).value = "Date: " + dateFormatted;
			detailsRow.getCell(4).value = "Time: " + fullTime;

			// Footer
			const footerRowIndex = cellRange.to.row + 2;
			const footerRow = worksheet.getRow(footerRowIndex);
			worksheet.mergeCells(footerRowIndex, 1, footerRowIndex, 8);
			footerRow.getCell(1).value = "END OF REPORT";
			footerRow.getCell(1).font = {
				color: {argb: "BFBFBF"},
				italic: true,
				size: 16,
			};
			footerRow.getCell(1).alignment = {horizontal: "center"};
			workbook.xlsx.writeBuffer().then((buffer) => {
				saveAs(
					new Blob([buffer], {type: "application/octet-stream"}),
					buildTitle
				);
			});
		});
		e.cancel = true;
	};

	const onEditCanceled = (e) => {
		setMode("View");
	};
	const onEditCanceling = async (e) => {
		if (canceled) {
			return;
		} else {
			e.cancel = true;
			const dialogResult = await confirm(
				"Unsaved data exist. <br/> Are you sure you want to cancel?"
			);
			if (dialogResult) {
				canceled = true;
				e.component.cancelEditData();
				canceled = false;
			}
		}
	};
	const getTotalCount = () => {
		let total = sessionStorage.getItem("totalCount");
		setTotalCount(total);
		groupCodeCheck();
	};
	const onSaving = (e) => {
		finalMsg = "";
		e.promise = confirm("Are you sure you want to save?").then((response) => {
			e.cancel = !response;
		});
	};
	const onSaved = (e) => {
		finalMsg = finalMsg.replace(/,/g, "<br/>");
		if (finalMsg !== "") {
			alert(finalMsg);
		}
		dataGrid.current.instance.getDataSource().store().resetResponseMsg();
		setMode("View");
	};
	const onRowPrepared = (e) => {
		if (e.rowType === "data" && e.data.isModified === true) {
			e.rowElement.classList.add("yellowBackground");
		}
		if (e.removed && e.data.isModified === true) {
			e.rowElement.classList.remove("yellowBackground");
		}
	};
	const onRowInserting = (e) => {
		e.data.plantId = plantId;
		e.data = [e.data];
	};
	const onRowInserted = (e) => {
		let localResultMsg = "";
		let message = e.data.data.message;

		if (e.data.statusText == "OK") {
			//e.data.status == 200
			finalMsg !== "" && !finalMsg.indexOf(message) && message !== undefined
				? (localResultMsg += "")
				: (localResultMsg = message);
		} else {
			finalMsg !== "" && !finalMsg.indexOf(message) && message !== undefined
				? (localResultMsg += "" + message)
				: (localResultMsg = message);
		}
		finalMsg += localResultMsg;
	};
	const onRowUpdating = (e) => {
		e.newData.sewsPartNo = e.oldData.sewsPartNo;
		e.newData.plantId = plantId;
		if (!e.newData.hasOwnProperty("componentCode")) {
			e.newData.componentCode = e.oldData.componentCode;
		}
		if (!e.newData.hasOwnProperty("deApproval")) {
			e.newData.deApproval = e.oldData.deApproval;
		}
		if (!e.newData.hasOwnProperty("qaApproval")) {
			e.newData.qaApproval = e.oldData.qaApproval;
		}
		e.newData = [e.newData];
	};
	const onRowUpdated = (e) => {
		let localResultMsg = "";
		let message = e.data.data.message;
		if (e.data.statusText == "OK") {
			//e.data.status == 200
			finalMsg !== "" && !finalMsg.indexOf(message)
				? (localResultMsg += "")
				: (localResultMsg = message);
		} else {
			finalMsg !== "" && !finalMsg.indexOf(message)
				? (localResultMsg += "")
				: (localResultMsg = message);
		}
		finalMsg += localResultMsg;
	};
	const onRowRemoving = (e) => {
		let sewsPartNo = e.data.sewsPartNo;
		e.data = {sewsPartNo: sewsPartNo, plantId: plantId};
		var changes = e.component.option("editing.changes");
		changes.find((c) => c.key === e.key).key = [e.data];
		e.component.option("editing.changes", changes);
	};
	const onRowRemoved = (e) => {
		// Customized getDeleteMsg in store in order to get msg from server
		let response = null;
		let localResultMsg = "";
		response = dataGrid.current.instance.getDataSource().store().getDeleteMsg();
		if (response && Object.keys(response).length !== 0) {
			let message = response.data.message;
			if (response.statusText == "OK") {
				finalMsg !== "" &&
				finalMsg.indexOf(message) !== -1 &&
				message !== undefined
					? (localResultMsg += "")
					: (localResultMsg = message);
			} else {
				finalMsg !== "" &&
				finalMsg.indexOf(message) !== -1 &&
				message !== undefined
					? (localResultMsg += "")
					: (localResultMsg = message);
			}
		}
		finalMsg += localResultMsg;
	};
	const getSingleSewsPartNo = (sewsNo) => {
		sewsNo = sewsNo.trim();
		let URLencoded = encodeURI(
			`${appInfo.configURL}ComponentCodeMaster/SewsPartNumber?plantid=${plantId}&id=${sewsNo}`
		);
		return axios
			.get(URLencoded, {
				headers: {
					Authorization: `Bearer ${token}`,
					"content-type": "application/x-www-form-urlencoded",
				},
			})
			.then((result) => {
				return result.data;
			});
	};
	async function fillColumns(newValue, value, row) {
		let localSewsPartNum = await getSingleSewsPartNo(value);
		newValue.customerCode = localSewsPartNum.customrCode;
		newValue.supplierCode = localSewsPartNum.supplierId;

		this.defaultSetCellValue(newValue, value);
	}

	const onOptionChanged = useCallback((e) => {
		if (e.fullName !== "editing.changes") {
			return;
		}

		if (getMode() === "View") {
			setMode("Edit");
		}
	}, []);

	if (permission && !permission.read) {
		return <Redirect to={"/home"} />;
	}
	return (
		<React.Fragment>
			<DataGrid
				elementAttr={{
					id: "componentCodeMasterDataGrid",
				}}
				ref={dataGrid}
				dataSource={customDataSource}
				allowColumnResizing={true}
				columnResizingMode="nextColumn"
				columnAutoWidth={true}
				columnMinWidth={100}
				showBorders={true}
				remoteOperations={true}
				onContentReady={getTotalCount}
				onExporting={onExporting}
				onEditCanceling={onEditCanceling}
				onEditCanceled={onEditCanceled}
				onRowPrepared={onRowPrepared}
				onRowUpdating={onRowUpdating}
				onRowUpdated={onRowUpdated}
				onRowInserting={onRowInserting}
				onRowInserted={onRowInserted}
				onRowRemoving={onRowRemoving}
				onRowRemoved={onRowRemoved}
				onSaving={onSaving}
				onSaved={onSaved}
				onOptionChanged={onOptionChanged}>
				<Toolbar>
					<Item location="before" cssClass="item-select-file-button">
						<FileUploader
							showFileList={false}
							allowedFileExtensions={[".xls", ".xlsx"]}
							uploadMode="instantly"
							multiple={false}
							uploadHeaders={{Authorization: `Bearer ${token}`}}
							uploadUrl={appInfo.configURL + "ComponentCodeMaster/Import"}
							onUploaded={function (e) {
								let finalMsg = JSON.parse(
									e.request.responseText
								).message.replace(/,/g, "<br/>");
								if (finalMsg !== "") {
									dataGrid.current.instance.refresh();
									alert(finalMsg);
								}
							}}
							onUploadError={function (e) {
								let finalMsg = JSON.parse(
									e.request.responseText
								).message.replace(/,/g, "<br/>");
								if (finalMsg !== "") {
									alert(finalMsg);
								}
							}}
						/>
					</Item>
					<Item location="after">
						<div className={"total-label"}>
							<span>Total Records: </span>
							<label>{totalCount}</label>
						</div>
					</Item>
					<Item location="after">
						<div className={"total-label"}>
							<span> Mode: </span>
							<label id="modeLabel">View</label>
						</div>
					</Item>
					<Item>
						<Button
							icon={IconFilter}
							className="dx-toolbar-item"
							hint="Clean Filters"
							onClick={clearFilters}
						/>
					</Item>
					<Item name="exportButton" />
					<Item name="addRowButton" />
					<Item name="revertButton" />
					<Item name="saveButton" />
					<Item name="searchPanel" />
				</Toolbar>
				<ColumnFixing enabled={true} />
				<LoadPanel enabled={true} />
				<Sorting mode="multiple" />
				<FilterRow
					visible={true}
					applyFilter={{key: "auto", name: "Immediately"}}
				/>
				<SearchPanel visible={true} width={240} placeholder="Search..." />
				<Export enabled={true} />
				<Editing
					mode="batch"
					startEditAction={dataGridSettings.startEditAction}
					allowAdding={permission ? permission.create : false}
					allowDeleting={permission ? permission.delete : false}
					allowUpdating={permission ? permission.update : false}
					texts={{saveAllChanges: "", cancelAllChanges: "", addRow: ""}}
					refreshMode="full"
				/>
				<Column
					dataField="id"
					caption="No"
					allowEditing={false}
					cssClass="cell-bold"
					visible={false}
					allowFiltering={false}
				/>
				<Column
					dataField="sewsPartNo"
					caption="SEWS Part No."
					cssClass="cell-bold"
					setCellValue={fillColumns}
					editorOptions={{ id: "sewsPartNo"}}
					editCellComponent={SEWSDropDownBoxComponent}>
					<RequiredRule />
				</Column>
				<Column
					dataField="customerCode"
					caption="Customer Code"
					cssClass="cell-bold"
					allowEditing={false}
				/>
				<Column
					dataField="supplierCode"
					cssClass="cell-bold"
					caption="Supplier Code"
					allowEditing={false}></Column>
				<Column
					dataField="componentCode"
					caption="Component Code"
					allowEditing={true}>
					<RequiredRule />
				</Column>
				<Column
					dataField="deApproval"
					caption="DE Approval"
					allowEditing={allowEditDEApproval}>
					<CheckBox></CheckBox>
				</Column>
				<Column
					dataField="deApprovedBy"
					caption="DE Approved By"
					allowEditing={false}></Column>
				<Column
					dataField="qaApproval"
					caption="QA Approval"
					allowEditing={allowEditQaApproval}>
					<CheckBox></CheckBox>
				</Column>
				<Column
					dataField="qaApprovedBy"
					caption="QA Approved By"
					allowEditing={false}></Column>
				<Paging defaultPageSize={10} defaultPageIndex={0} />
				<Pager
					visible={true}
					allowedPageSizes={allowedPageSizes}
					displayMode="full"
					showPageSizeSelector={dataGridSettings.showPageSizeSelector}
					showInfo={dataGridSettings.showInfo}
					showNavigationButtons={dataGridSettings.showNavButtons}
				/>
				<Scrolling mode={dataGridSettings.infiniteScroll} rowRenderingMode='virtual' />
			</DataGrid>
		</React.Fragment>
	);
};
