//*******************************************************************************************************************
//File Name          :   CustomerGroupMaster.js
//Function           :   Customer group frontend module for FGSSMaster
//Created By         :   
//Created on         :   
 
//Revision History   :
//Date           Developer   Ticket#         Modification Description
//--------------------------------------------------------------------------------------------------
//12-Mar-2025    CV          (HDT#:775711)   Modification Related to Customer group new module.
//*******************************************************************************************************************
import React, {useRef, useState, useCallback} from "react";
import store from "../../components/hooks/store";
import appInfo from "../../app-info";
import { getPermission, getMode, setMode } from "./helpers/globalFunctions";
import DataGrid, {
	Column,
	Editing,
	Paging,
	FilterRow,
	SearchPanel,
	Toolbar,
	Pager,
	Scrolling,
	Sorting,
	ColumnFixing,
  Export,
  RequiredRule
} from "devextreme-react/data-grid";
import { confirm, alert } from "devextreme/ui/dialog";
import Button from "devextreme-react/button";
import { Redirect } from "react-router-dom";
import { Item } from "devextreme-react/box";
import IconFilter from "../../components/filter.svg";
//import CustomStore from 'devextreme/data/custom_store';
//import notify from "devextreme/ui/notify";
import {Workbook} from "exceljs";
import {exportDataGrid} from "devextreme/excel_exporter";
import saveAs from "file-saver";

//css stylesheets
import './css/customerGroupMaster.scss'

//temporary data
const CustomerGroupMaster = () => {
  const [items, setItems] = useState(["Standard", "Inventory"]);
	const [optionSelected, setOptionSelected] = useState(items[0]);
	const [disableMfgColumn, setDisableMfgColumn] = useState(false);
	const [customDataSource, setCustomDataSource] = useState(
		store({
			url: `${appInfo.configURL}CustomerGroup`
		})
	);

	let currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
	let language = currentUser ? currentUser.data.userData.language : '';
	let canceled;
	let finalMsg = "";
  	const permission = getPermission(currentUser, "ConfigurationMasters", "GROUPMASTER");
	const [totalCount, setTotalCount] = useState(0);

	const dataGrid = useRef(null);
	const allowedPageSizes = [10, 30, 50, "all"];
	const [dataGridSettings, setdataGridSettings] = useState({
		selectTextOnEditStart: true,
		startEditAction: "click",
		showHeaderFilter: true,
		showPageSizeSelector: true,
		showFilterRow: true,
		showInfo: true,
		showNavButtons: true,
		infiniteScroll: "standard"
	});

  //datagrid functions
  const getTotalCount = () => {
		let currentTotal = sessionStorage.getItem("totalCount");
		setTotalCount(currentTotal);
	};
  const clearFilters = () => {
		dataGrid.current.instance.clearFilter();
	};
  const customizeText = (cellInfo) => {
		let localValue = cellInfo.value;
		if (localValue) {
			localValue = localValue.toUpperCase();
			return localValue;
		}
	};
  	const onEditCanceled = useCallback((e) => {
		setMode("View");
	}, []);

	const onEditCanceling = useCallback((e) => {
		if (canceled) {
			return;
		} else {
			e.cancel = true;
			confirm("Unsaved data exist. <br/> Are you sure you want to cancel?").then((confirm) => {
				if (confirm) {
					canceled = true;
					e.component.cancelEditData();
					canceled = false;
				}
			});
		}
	}, []);
  //Insert methods
  //This might prepare the data to send it to the custom store
  const onRowInserting = (e) => {
	//most of the time you will be sending options because the store handles them
    e.data.option = optionSelected;
	//next line should change e.data to array so it will fit the criteria of post
    e.data = [e.data];
	};
  //This just handles the mesage to show after inserction
  const onRowInserted = (e) => {
		let localResultMsg = "";
		let message = e.data.data.message;
    	
		if (e.data.statusText == "OK") {
			//e.data.status == 200
			finalMsg !== "" && !finalMsg.indexOf(message) && message !== undefined
				? (localResultMsg += "")
				: (localResultMsg = message);
		} else {
			finalMsg !== "" && !finalMsg.indexOf(message) && message !== undefined
				? (localResultMsg += "" + message)
				: (localResultMsg = message);
		}
		finalMsg += localResultMsg;
	};
  //End insert methods
  //Delete methods
  const onRowRemoving = (e) => {
		let changes = e.component.option("editing.changes");
		changes.find((c) => c.key === e.key).key = [e.data];
		e.component.option("editing.changes", changes);
	};

  const onRowRemoved = (e) => {
		// Customized getDeleteMsg in store in order to get msg from server
		let response = null;
		let localResultMsg = "";
		response = dataGrid.current.instance.getDataSource().store().getDeleteMsg();
		if (response && Object.keys(response).length !== 0) {
			let message = response.data.message;
			if (response.statusText == "OK") {
				finalMsg !== "" && finalMsg.indexOf(message) !== -1 && message !== undefined
					? (localResultMsg += "")
					: (localResultMsg = message);
			} else {
				finalMsg !== "" && finalMsg.indexOf(message) !== -1 && message !== undefined
					? (localResultMsg += "")
					: (localResultMsg = message);
			}
		}
		finalMsg += localResultMsg;
	};
  //End Delete methods
  //Update Methods
  const onRowUpdating = (e) => {
		e.newData.option = e.oldData.option;
		e.newData = [{...e.oldData, ...e.newData}];
	};

  const onRowUpdated = (e) => {
		let localResultMsg = "";
		let message = e.data.data.message;
		if (e.data.statusText == "OK") {
			//e.data.status == 200
			finalMsg !== "" && !finalMsg.indexOf(message)
				? (localResultMsg += "")
				: (localResultMsg = message);
		} else {
			finalMsg !== "" && !finalMsg.indexOf(message)
				? (localResultMsg += "")
				: (localResultMsg = message);
		}
		finalMsg += localResultMsg;
	};
  //End Update Methods
  //Export file methods
  const onExporting = (e) => {
		/* Get today Date First */
		let today = new Date();
		let month = today.getMonth() + 1; //months from 1-12
		let day = today.getDate();
		let year = today.getUTCFullYear();

		let hours = today.getHours();
		let minutes = today.getMinutes();
		let ampm = hours >= 12 ? "pm" : "am";
		hours = hours % 12;
		hours = hours ? hours : 12; // the hour '0' should be '12'
		minutes = minutes < 10 ? "0" + minutes : minutes;
		let fullTime = hours + ":" + minutes + " " + ampm;
		let buildTitle;

		language === "ES"
			? (buildTitle = `CustGroupMaster_${day}-${month}-${year}.xlsx`)
			: (buildTitle = `CustGroupMaster_${month}-${day}-${year}.xlsx`);
		const workbook = new Workbook();
		const worksheet = workbook.addWorksheet(buildTitle);

		exportDataGrid({
			component: e.component,
			worksheet: worksheet,
			topLeftCell: {row: 1, column: 1},
			customizeCell: ({gridCell, excelCell}) => {
				if (gridCell.rowType === "header") {
					excelCell.fill = {
						type: "pattern",
						pattern: "solid",
						fgColor: {argb: "e8e9eb"}
					};
          excelCell.alignment = { horizontal: 'left' }
				}
			}
		}).then(() => {
			workbook.xlsx.writeBuffer().then((buffer) => {
				saveAs(new Blob([buffer], {type: "application/octet-stream"}), buildTitle);
			});
		});
		e.cancel = true;
	};
  //End Export file methods
  const onOptionChanged = useCallback((e) => {
		//just switches from edit to view modes
    if (e.fullName !== "editing.changes") {
			return;
		}

		if (getMode() === "View") {
			setMode("Edit");
		}
	}, []);

  const onValueChanged = (e) => {
		setOptionSelected(e.value);
		if (e.value === "Inventory") {
			setDisableMfgColumn(true);
		} else {
			setDisableMfgColumn(false);
		}
		setCustomDataSource(
			store({
				url: `${appInfo.configURL}CustomerGroup?option=${e.value}`
			})
		);
	};

	const onSaving = (e) => {
		finalMsg = "";
		e.promise = confirm("Are you sure you want to save?").then((response) => {
			e.cancel = !response;
		});
	};
  const onSaved = (e) => {
		finalMsg = finalMsg.replace(/,/g, "<br/>");
		alert(finalMsg);
		dataGrid.current.instance.getDataSource().store().resetResponseMsg();
		setMode("View");
	};
  if (permission && !permission.read) {
		return <Redirect to={"/home"} />;
	}

  return (
    <>
      <div className="header-left-side">
        <div className="header">
          <h2>Customer Group Master</h2>
        </div>
      </div>
      <DataGrid
        elementAttr={{
          id: "sysParamGridContainer",
        }}
        ref={dataGrid}
        dataSource={customDataSource}
        remoteOperations={true}
        showBorders={true}
        allowColumnResizing={true}
        columnResizingMode="nextColumn"
        columnAutoWidth={true}
        columnMinWidth={100}
        repaintChangesOnly={true}
        onContentReady={getTotalCount}
        onEditCanceled={onEditCanceled}
        onEditCanceling={onEditCanceling}
        onRowInserting={onRowInserting}
        onRowInserted={onRowInserted}
        onRowRemoving={onRowRemoving}
        onRowRemoved={onRowRemoved}
        onRowUpdating={onRowUpdating}
        onRowUpdated={onRowUpdated}
        onExporting={onExporting}
		onSaving={onSaving}
        onSaved={onSaved}
        onOptionChanged={onOptionChanged}
        onValueChanged={onValueChanged}
      >
        <Export enabled={true} />
        <ColumnFixing enabled={true} />
        <Toolbar>
          <Item location="after">
            <div className={"total-label"}>
              <span>Total Records: </span>
              <label>{totalCount}</label>
            </div>
          </Item>
          <Item location="after">
            <div className={"total-label"}>
              <span> Mode: </span>
              <label id="modeLabel">View</label>
            </div>
          </Item>
          <Item>
            <Button
              icon={IconFilter}
              className="send dx-toolbar-item"
              hint="Clean Filters"
              onClick={clearFilters}
            />
          </Item>
          <Item name="addRowButton" />
          <Item name="revertButton" />
          <Item name="saveButton" />
          <Item name="exportButton" />
          <Item name="searchPanel" />
        </Toolbar>
        <Sorting mode="multiple" />
        <SearchPanel visible={true} width={240} placeholder="Search..." />
        <FilterRow visible={true} />
        <Editing
          mode="batch"
          allowUpdating={true}
          allowAdding={permission ? permission.create : false}
          allowDeleting={permission ? permission.delete : false}
          selectTextOnEditStart={dataGridSettings.selectTextOnEditStart}
          startEditAction={dataGridSettings.startEditAction}
          texts={{ saveAllChanges: "", cancelAllChanges: "" }}
        />
        <Column
          dataField="id"
          allowEditing={false}
          allowFiltering={false}
          visible={false}
          cssClass="cell-bold small-space"
        ></Column>
        <Column
          dataField="idcust_Group"
          allowEditing={false}
          allowFiltering={false}
          visible={false}
          cssClass="cell-bold small-space"
        ></Column>
        <Column
          dataField="cust_Group"
          caption="Customer Group"
          customizeText={customizeText}
          editorOptions={{ maxLength: 20 }}
          cssClass="small-space"
        >
          <RequiredRule />
        </Column>
        <Paging defaultPageSize={10} />
        <Pager
          visible={true}
          allowedPageSizes={allowedPageSizes}
          displayMode="full"
          showPageSizeSelector={dataGridSettings.showPageSizeSelector}
          showInfo={dataGridSettings.showInfo}
          showNavigationButtons={dataGridSettings.showNavButtons}
        />
        <Scrolling
          mode={dataGridSettings.infiniteScroll}
          rowRenderingMode="virtual"
        />
      </DataGrid>
    </>
  );
}
export default CustomerGroupMaster