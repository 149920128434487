//*******************************************************************************************************************
//File Name          :   ComponentCodeDropDownBox.js
//Function           :   Manages the functions to add more SEWS parts store and data grid.
//Created By         :   
//Created on         :   
 
//Revision History   :
//Date           Developer   Ticket#         Modification Description
//--------------------------------------------------------------------------------------------------
//05-Mar-2025    CV          (HDT#:784207)   Modified data source and store load function to add error validation and refactored logic
//*******************************************************************************************************************
import React from 'react';
import DataGrid, {
  Column,
  Paging,
  Scrolling,
  Selection,
  FilterRow
} from 'devextreme-react/data-grid';
import DropDownBox from 'devextreme-react/drop-down-box';
import DataSource from 'devextreme/data/data_source';
import CustomStore from 'devextreme/data/custom_store';
import appInfo from '../../../app-info';
import notify from 'devextreme/ui/notify';

const dropDownOptions = { width: 500 };
const isNotEmpty = (value) => value !== undefined && value !== null && value !== '';
const buildQueryParams = (loadOptions, validParams = ['filter', 'requireTotalCount', 'searchValue', 'skip', 'take']) => {
  const params = new URLSearchParams();

  validParams.forEach((key) => {
    if (key in loadOptions && isNotEmpty(loadOptions[key])) {
      params.append(key, JSON.stringify(loadOptions[key]));
    }
  });

  return params.toString();
};


const ds = new DataSource({
  store: new CustomStore({
    byKey: function (key) {
      return key;
    },
    load: async (loadOptions) => {
      const currentUserString = sessionStorage.getItem("currentUser");
      const currentUser = currentUserString ? JSON.parse(currentUserString) : null;
      const token = currentUser.data.token;
      const currentPlant = sessionStorage.getItem('currentPlant');

      if(!currentUser || !currentPlant){
        notify("There isn't a session available to bring the SEWS part list", "error", 5000)
        return [];
      }

      const queryString = buildQueryParams(loadOptions);
      
      try {
        const response = await fetch(
          `${appInfo.configURL}ComponentCodeMaster/SewsPartNumbers?plantid=${currentPlant}${queryString}`,
          {
            headers: { "Authorization": `Bearer ${token}` }
          }
        );
        if(!response.ok){
          console.error(`Error while fetching information: ${response.status} ${response.statusText}`);
          notify("Unable to retrieve SEWS parts for list. Server might be very busy, refresh or try again later", "error", 7000);
          return [];
        }
        const data = await response.json();
        return data;

      } catch (error) {
        console.error("Couldn't fetch the list of SEWS parts, server might be busy or down, please refresh...",error);
        notify("Couldn't fetch the list of SEWS parts, server might be busy or down, please refresh...","error",7000);
        return []; //fallback if an error occurs and prevent custom store issues
      }
    },
    //other store methods
  })
});

export default class SEWSDropDownBoxComponent extends React.Component {
  constructor(props) {
    super(props);
    let newRow = props.data.row.isNewRow ? false : true;
    this.state = {
      selectedValue: props.data.value,
      isDropDownOpened: false,
      disableDG: newRow,
    };

    this.onSelectionChanged = this.onSelectionChanged.bind(this);
    this.contentRender = this.contentRender.bind(this);
    this.boxOptionChanged = this.boxOptionChanged.bind(this);
  }

  boxOptionChanged(e) {
    if (e.name === 'opened') {
      this.setState({
        isDropDownOpened: e.value,
      });
    }
  }

  contentRender() {
    return (
      <DataGrid
        dataSource={ds}
        height={300}
        hoverStateEnabled={true}
        onSelectionChanged={this.onSelectionChanged}
      >
        <Column dataField="sewsPartNo" />
        <Column dataField="customrCode" />
        <Column dataField="supplierId" />
        <Paging enabled={true} defaultPageSize={10} />
        <Scrolling mode="virtual" />
        <Selection mode="single" />
        <FilterRow visible={true} applyFilter={{ key: 'auto', name: 'Immediately' }} />
      </DataGrid>
    );
  }

  onSelectionChanged(selectionChangedArgs) {
    this.setState({
      selectedValue: selectionChangedArgs.selectedRowsData[0].sewsPartNo,
      isDropDownOpened: false,
    });

    this.props.data.setValue(this.state.selectedValue);
  }

  render() {
    return (
      <DropDownBox
        onOptionChanged={this.boxOptionChanged}
        opened={this.state.isDropDownOpened}
        disabled={this.state.disableDG}
        dropDownOptions={dropDownOptions}
        dataSource={ds}
        value={this.state.selectedValue}
        displayExpr="sewsPartNo"
        valueExpr="sewsPartNo"
        contentRender={this.contentRender}>
      </DropDownBox>
    );
  }
}