//*******************************************************************************************************************
//File Name          :   RetransmitASNCentraltoCustoms.js
//Function           :   Retransmit ASN to customs form and report for central server.
//Created By         :   
//Created on         :   
 
//Revision History   :
//Date           Developer   Ticket#         Modification Description
//--------------------------------------------------------------------------------------------------
//19-Feb-2025    CV          (HDT#:779040)   Modification Related to grid state preservation and new error notification.
//*******************************************************************************************************************
import React, {useState, useRef, useEffect} from "react";
import {Button} from "devextreme-react/button";
import DateBox from "devextreme-react/date-box";
import DataGrid, {
	Column,
	Paging,
	FilterRow,
	Pager,
	Toolbar,
	ColumnFixing,
	Item,
	RemoteOperations,
	Scrolling,
	Selection,
	Export
} from "devextreme-react/data-grid";
import notify from 'devextreme/ui/notify';
import store from "../../components/hooks/store";
import appInfo from "../../app-info";
import {Redirect} from "react-router-dom";
import {getPermission} from "../configuration/helpers/globalFunctions";
import {Tooltip} from "devextreme-react/tooltip";
import {alert, confirm} from "devextreme/ui/dialog";
import {LoadPanel} from "devextreme-react/load-panel";
import IconFilter from "../../components/filter.svg";

export default () => {
	const allowedPageSizes = [10, 30, 50, "all"];
	const [totalCount, setTotalCount] = useState(0);
	const [customDataSource, setCustomDataSource] = useState(null);
	const [disableSearchBtn, setdisableSearchBtn] = useState(true);
	const [disableConfirmBtnCentral, setDisableConfirmBtnCentral] = useState(true);
	const [disableConfirmAllBtnCentral, setConfirmAllBtnCentral] = useState(true);

	const dataGrid = useRef(null);
	let currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
	let formatDate = currentUser ? currentUser.data.userData.formatDate : '';
	let token = currentUser ? currentUser.data.token : '';
	const permission = getPermission(currentUser, "ImportExport", "RetransmitASNCentraltoCustoms");
	const [loadPanelVisible, setLoadPanelVisible] = useState(false);

	const [dataGridSettings, setdataGridSettings] = useState({
		showHeaderFilter: true,
		showPageSizeSelector: true,
		showFilterRow: true,
		showInfo: true,
		showNavButtons: true,
		mode: "View",
		infiniteScroll: "standard"
	});
	/* Instance components in order to reset them when necessary */
	const dateBox = useRef(null);
	const currentDate = new Date();

	const [showTootlTip, setShowTooltip] = useState(true);
	const [dateValue, setDateVal] = useState();
	const [firstTimeLoading, setfirstTimeLoading] = useState(true);

	const sendReportRequest = () => {
		try {
			let dateSelected = JSON.stringify(dateValue).replace(/"/g, "");
		
			const newStore = store({
			  url: `${appInfo.importURL}RetransmitASNCentralToCustoms/AsnNumbers?date=${dateSelected}`,
			  setConfirmAllBtnCentral,
			  setDisableConfirmBtnCentral
			});
		
			setCustomDataSource(newStore);
		  } catch (error) {
			console.warn("Error setting store:", error);
			notify("An error occurred while fetching report data.", "error", 5000);
		
			setDisableConfirmBtnCentral(true);
			setConfirmAllBtnCentral(true);
		  } finally {
			setShowTooltip(false);
			setfirstTimeLoading(false);
		  }
	};

	const clearFilters = () => {
		setDateVal(currentDate);
		setShowTooltip(true);
		setDisableConfirmBtnCentral(true);
		setConfirmAllBtnCentral(true);
		setCustomDataSource({
			url: `${appInfo.reportURL}RetransmitASNCentralToCustoms`
		});
		sessionStorage.removeItem("totalCount");
		sessionStorage.setItem("totalCount", 0); //reset totalcount
	};
	const getDate = (e) => {
		setDateVal(e.value);
		if (e.value !== null) {
			setdisableSearchBtn(false);
		}
	};
	const getTotalCount = () => {
		//this goes in onContentReady
		if (!firstTimeLoading) {
			let total = sessionStorage.getItem("totalCount");
			setTotalCount(total);
		} else {
			setTotalCount(0);
		}
	};
	const confirmAll = async (retryCount = 0) => {
		retryCount = Number(retryCount) || 0;
		
		if(retryCount === 0){
			const dialogResult = await confirm("[5661] Are you sure you want to retransmit all ASN?");
			if(!dialogResult){
				return
			}
		}
			let dateSelected = JSON.stringify(dateValue);
			dateSelected = dateSelected.replace(/"/g, "");
			changeLoadPanelStatus(true);
			setDisableConfirmBtnCentral(true);
			setConfirmAllBtnCentral(true);

			const controller = new AbortController();
			const signal = controller.signal;
	
			const timeoutId = setTimeout(() => {
				controller.abort();
			}, 300000);

			try {
				const response = await fetch(
					`${appInfo.importURL}RetransmitASNCentralToCustoms/Retransmit?asnNumber=&date=${dateSelected}&all=true`,
					{
						method: "post",
						headers: {
							Authorization: `Bearer ${token}`,
							"Content-Type": "application/json"
						},
						signal
					}
				);
				clearTimeout(timeoutId);
	
				if (!response.ok) {
					notify(`Error ${response.status}: ${response.statusText}`, "error", 5000);
					return;
				}

				const data = await response.json();
				alert(data.message);
			} catch (err){
				console.warn(err);
				if (err.name === "AbortError") {
					notify("Request timed out or was canceled...", "error", 5000);
				} else {
					notify(`Network error: Unable to reach the server. It may be down or busy. Error log: ${err.message}`, "error", 5000);
				}
				if (retryCount < 3) {
					const retryDialog = await confirm("[5661] It's taking more time than expected, do you want to retry retransmitting to ASN?");
					if (retryDialog) {
						await confirmAll(Number(retryCount) + 1);
					}
				} else {
					notify("Maximum retry attempts reached, the server seems to be very busy, try again later", "error", 5000);
				}
			} finally {
				setConfirmAllBtnCentral(false);
				changeLoadPanelStatus(false);
			}
		
	};
	const confirmAction = async (retryCount = 0) => {
		retryCount = Number(retryCount) || 0;
	
		let selectedRows = dataGrid.current.instance.getSelectedRowsData();
	
		if (selectedRows.length === 0) {
			alert("Please select ASN Number");
			return;
		}
	
		if (retryCount === 0) {
			const dialogResult = await confirm("[5661] Are you sure you want to retransmit ASN?");
			if (!dialogResult) return;
		}
	
		changeLoadPanelStatus(true);
		setDisableConfirmBtnCentral(true);
	
		let getASNnumbers = selectedRows.map((row) => row.asnNumber);
		let asnNumberString = getASNnumbers.toString();
	
		const controller = new AbortController();
		const signal = controller.signal;
	
		const timeoutId = setTimeout(() => {
			controller.abort();
		}, 300000);
	
		try {
			const response = await fetch(
				`${appInfo.importURL}RetransmitASNCentralToCustoms/Retransmit?date=&all=false&asnNumber=${asnNumberString}`,
				{
					method: "post",
					headers: {
						Authorization: `Bearer ${token}`,
						"Content-Type": "application/json",
					},
					signal,
				}
			);
	
			clearTimeout(timeoutId);
	
			if (!response.ok) {
				notify(`Error ${response.status}: ${response.statusText}`, "error", 5000);
				return;
			}
	
			const data = await response.json();
			alert(data.message);
		} catch (err) {
			console.warn("Error occurred:", err);
	
			if (err.name === "AbortError") {
				notify("Request timed out or was canceled...", "error", 5000);
			} else {
				notify(`Network error: Unable to reach the server. It may be down or busy. Error log: ${err.message}`, "error", 5000);
			}
			if (retryCount < 3) {
				const retryDialog = await confirm("[5661] It's taking more time than expected, do you want to retry retransmitting to ASN?");
				if (retryDialog) {
					await confirmAction(Number(retryCount) + 1);
				}
			} else {
				notify("Maximum retry attempts reached, the server seem to be very busy, try again later", "error", 5000);
			}
		} finally {
			changeLoadPanelStatus(false);
		}
	};
	
	const changeLoadPanelStatus = (boolValue) => {
		setLoadPanelVisible(boolValue);
		
		if (!boolValue) {
			setDisableConfirmBtnCentral(boolValue);
			sessionStorage.setItem("totalCount", 0); // Reset total count
		}
	};
	const onSelectionChanged = (e) => {
		if (e.selectedRowKeys.length > 0) {
			setConfirmAllBtnCentral(true);
		} else {
			setConfirmAllBtnCentral(false);
		}
	};
	const clearFiltersDG = () => {
		dataGrid.current.instance.clearFilter();
	};
	if (permission && !permission.read) {
		return <Redirect to={"/home"} />;
	}
	return (
		<React.Fragment>
			<div>
				<div className='header-left-side'>
					<div className='header'>
						<h2>Retransmit ASN (Central) to Customs</h2>
					</div>
				</div>
				<form className={"filter-form"}>
					<div className='dx-fieldset'>
						<br />
						<div className='dx-field'>
							<div className='dx-labels'>ASN Date</div>
							<DateBox
								elementAttr={{
									id: "shipmentDateFrom"
								}}
								ref={dateBox}
								useMaskBehavior={true}
								placeholder='(None)'
								displayFormat={formatDate}
								width='200px'
								value={dateValue}
								dateSerializationFormat='yyyy/MM/dd'
								onValueChanged={getDate}
							/>
						</div>
						<div className='dx-field'>
							<Button
								icon='find'
								elementAttr={{
									id: "ANSfindButton"
								}}
								disabled={disableSearchBtn}
								onClick={sendReportRequest}
							/>
							<Tooltip target='#findButton' visible={showTootlTip} position='bottom'>
								<div>* Click on Search Icon to get Results </div>
							</Tooltip>
						</div>
						<div className='dx-field'>
							<Button icon='clear' elementAttr={{id: "ANScancelButton"}} onClick={clearFilters} />
						</div>
					</div>
				</form>
				<span>&nbsp;</span>
				<span>&nbsp;</span>
				<LoadPanel visible={loadPanelVisible} showIndicator={true} shading={true} showPane={true} />
				<DataGrid
					elementAttr={{
						id: "RetransmitContainer"
					}}
					dataSource={customDataSource}
					ref={dataGrid}
					showBorders={true}
					columnResizingMode='widget'
					columnMinWidth={100}
					allowColumnResizing={true}
					onContentReady={getTotalCount}
					onSelectionChanged={onSelectionChanged}>
					<Toolbar>
						<Item location='before'>
							<div>
								<Button
									icon='checklist'
									className='dx-toolbar-item'
									hint='Confirm'
									text='Confirm'
									disabled={disableConfirmBtnCentral}
									onClick={confirmAction}
								/>
							</div>
						</Item>
						<Item location='before'>
							<div>
								<Button
									icon='selectall'
									className='dx-toolbar-item'
									disabled={disableConfirmAllBtnCentral}
									hint='Confirm All'
									text='Confirm All'
									onClick={confirmAll}
								/>
							</div>
						</Item>
						<Item location='after'>
							<div className={"total-label"}>
								<span>Total Records: </span>
								<label>{totalCount}</label>
							</div>
						</Item>
						<Item location='after'>
							<div className={"total-label"}>
								<span> Mode: </span>
								<label>{dataGridSettings.mode}</label>
							</div>
						</Item>
						<Item>
							<Button
								icon={IconFilter}
								className='dx-toolbar-item'
								hint='Clean Filters'
								onClick={clearFiltersDG}
							/>
						</Item>
					</Toolbar>
					<ColumnFixing enabled={true} />
					<Selection mode='multiple' allowSelectAll={false} showCheckBoxesMode='always' />
					<RemoteOperations groupPaging={true} />
					<Export enabled={true} />
					<FilterRow visible={true} applyFilter={{key: "auto", name: "Immediately"}} />
					<Column dataField='id' caption='ID' visible={false} />
					<Column dataField='plantCode' caption='Plant Code' />
					<Column dataField='asnNumber' caption='ASN Number' />
					<Column dataField='shipDate' caption='Ship Date' dataType='date' format={formatDate} />
					<Paging defaultPageSize={10} defaultPageIndex={0} />
					<Pager
						visible={true}
						allowedPageSizes={allowedPageSizes}
						displayMode='full'
						showPageSizeSelector={dataGridSettings.showPageSizeSelector}
						showInfo={dataGridSettings.showInfo}
						showNavigationButtons={dataGridSettings.showNavButtons}
					/>
					<Scrolling mode={dataGridSettings.infiniteScroll} rowRenderingMode='virtual'/>
				</DataGrid>
			</div>
		</React.Fragment>
	);
};
