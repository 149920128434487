//*******************************************************************************************************************
//File Name          :   home.js
//Function           :   Home reactJS component
//Created By         :   
//Created on         :   
//Revision History   :
//Date           Developer   Ticket#         Modification Description
//--------------------------------------------------------------------------------------------------
//25-Mar-2025    CV          (HDT#:787332)   Removed extra container to enlarge homepage image.
//*******************************************************************************************************************
import React from "react";
import "./home.scss";

export default () => (
	<div className={"home-container"}>
		<img
			src={"../../../FGSS_Image.png"}
			alt="Sumitomo Homepage"
			className={"home-image"}
		/>
	</div>
);
